<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" style="width: 200px;" placeholder="员工姓名、登录帐号"></el-input>
			</div>

			<div class="filter-item">
				<label class="label">角色: </label>
				<el-select v-model="dataRolesType" placeholder="请选择" filterable clearable style="margin-right: 20px;">
					<el-option :key="-1" label="全部" :value="null">
					</el-option>
					<el-option v-for="(item,index) in dataRoles" :key="index" :label="item.Name" :value="item.Id">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item" v-if="mallInfo.IsMoreShop&&!IsXiTai">
				<label class="label">归属门店: </label>
				<el-select v-model="doorListType" placeholder="请选择" filterable clearable>
					<el-option :key="-1" label="全部" :value="null">
					</el-option>
					<el-option v-for="(item,index) in doorList" :key="index" :label="item.ShopName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label">在职状态: </label>
				<el-select v-model="EmployeeState" placeholder="请选择" filterable style="margin-right:20px;">
					<!-- <el-option :key="-1" label="全部" :value="null"> -->
					<!-- </el-option> -->
					<el-option v-for="(item,index) in EmployeeStateList" :key="index" :label="item.Name" :value="item.Id">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item" v-if="!IsXiTai">
				<label class="label">是否客服: </label>
				<el-select v-model="IsCustomerService" placeholder="请选择" filterable style="margin-right: 20px;">
					<el-option :key="-1" label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in CustomerServiceList" :key="index" :label="item.Name" :value="item.Id">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addStaff'">
					<el-button type="primary" style="margin-left:30px;width:90px;" @click="AddEmployee">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" ref="tableemployees"  @selection-change="SelectionChange" border style="width: 100%">
				<el-table-column type="selection" v-if="batchclickshow" width="40"></el-table-column>
				<el-table-column prop="EmployeeName" label="员工" width="250px;">
					<template slot-scope="scope">
						<div style="display: flex;flex-direction: row;align-items: center;">
							<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;border:1px solid #ddd;" v-if="scope.row.WxHeadUrl==''"
							 src='https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png' />
							<!-- <img style="width:50px;height:50px;border-radius:100%;object-fit:contain;" v-if="scope.row.WxHeadUrl==''" src='https://cdn.dkycn.cn/images/melyshop/pcDefaultHeader.png'/> -->
							<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;border:1px solid #ddd;" v-else :src='scope.row.WxHeadUrl' />
							<span style="margin-left:5px;">{{scope.row.EmployeeName}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="Phone" label="登录帐号"></el-table-column>
				<el-table-column prop="Name" label="角色"></el-table-column>
				<el-table-column prop="ShopName" label="归属门店" v-if="mallInfo.IsMoreShop&&!IsXiTai"></el-table-column>
				<el-table-column label="锁粉客户" v-if="!IsXiTai">
					<template slot-scope="scope">
						<span v-if="scope.row.LockCustomerCount==0">0</span>
						<el-button v-else type="text" @click="jumpToLockCustomer(scope.row.Id)">{{scope.row.LockCustomerCount}}</el-button>
					</template>
				</el-table-column>

				<el-table-column prop="AddTime" label="匿名锁粉" v-if="!IsXiTai">
					<template slot-scope="scope">
						<span v-if="scope.row.FansCount==0">0</span>
						<el-button v-else type="text" @click="anonymousLock(scope.row.Id)">{{scope.row.FansCount }}</el-button>
					</template>
				</el-table-column>

				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="300" align="center">
					<template slot-scope="scope">
						<div  v-if="scope.row.EmployeeStateValue != '离职'">
							<button-permissions :datas="'editStaff'" style="margin-right: 10px;">
								<el-button type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
							</button-permissions>

							<button-permissions :datas="'groupID'" style="margin-right: 10px;" v-if="scope.row.EmployeeStateValue=='在职'||scope.row.EmployeeStateValue=='待分配'">
								<el-button type="text" @click="handleGroupId(scope.row)">社群ID</el-button>
							</button-permissions>

							<el-dropdown  >
								<el-button  type="text">
									更多
								</el-button>
								<el-dropdown-menu slot="dropdown">
									
									<el-dropdown-item  v-if="!IsXiTai">
										<el-button type="text" @click="handleInviteCode(scope.row)">邀请码</el-button>
									</el-dropdown-item>
									
									<el-dropdown-item >
										<button-permissions :datas="'deleteStaff'" v-if="scope.row.EmployeeStateValue=='在职'||scope.row.EmployeeStateValue=='待分配'">
											<el-button type="text" @click="handleLeave(scope.$index, scope.row)" style="color: #F56C6C !important;">离职</el-button>
										</button-permissions>
									</el-dropdown-item>
									
									<el-dropdown-item >
										<button-permissions :datas="'TransferCustomer'" v-if="scope.row.EmployeeStateValue=='在职'||scope.row.EmployeeStateValue=='待分配' || scope.row.EmployeeStateValue=='离职'">
											<el-button type="text" @click="handleMigrate(scope.row)">转移客户</el-button>
										</button-permissions>
									</el-dropdown-item>

									<el-dropdown-item >
										<button-permissions :datas="'staffMangesearchwx'">
											<el-button type="text" @click="searchwx(scope.row)">查询关联微信</el-button>
										</button-permissions>
									</el-dropdown-item>

								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<!-- 产品说 离职员工只保留转移客户 -->
						<div v-else>
							<button-permissions :datas="'TransferCustomer'">
										<el-button type="text" @click="handleMigrate(scope.row)">转移客户</el-button>
								</button-permissions>
						</div>


					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right" v-if="total">
				<el-checkbox  @change="tabCheckedChange"  v-show="batchclickshow" v-model="checked" style="margin:10px 0px 0px 15px;float:left">当前页全选</el-checkbox>
				<button-permissions :datas = "'batchamend'" style="margin:5px 0px 0px 20px;float:left">
					<el-button size="mini" v-show="batchclickshow"  @click="batchamend">批量修改角色</el-button>
				</button-permissions>
				<el-pagination style="margin-top:20px;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="pageIndex" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="total">
				</el-pagination>
			</div>
		</div>

		<!-- 添加员工弹窗 -->
		<el-dialog :title="`${editDiaTitle}员工`" :visible.sync="dialogFormVisible" v-if="dialogFormVisible" width="550px" custom-class="dialogpad">
			<div class="yellowbox" v-if="iscompletebind">
				提示：商城已绑定企业微信。受企业微信限制，登录帐号（即员工手机号）作为企业成员敏感信息，服务商（企店）已无修改权限，在此处修改成功后，仅影响登录商城系统的帐号，企业微信绑定的手机号请员工自行在企业微信APP上修改。
			</div>
			<el-form :model="form" :rules="formRules" ref="form" v-loading='editLoading'>
				<el-form-item label="员工姓名 :" label-width="150px" prop="EmployeeName">
					<el-input auto-complete="on"  :disabled='editResign' placeholder="最多输入20个字" maxlength="20" v-model="form.EmployeeName" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="登录帐号 :" label-width="150px" prop="Phone">
					<el-input auto-complete="on"  :disabled='caneditphone' placeholder="请输入员工手机号" maxlength="11"
					 v-model="form.Phone" style="width: 300px;"></el-input>
					<div  style="color:#909399;line-height:20px;font-size:12px;width:300px;overflow-wrap: break-word;">
						请填写真实手机号；手机号作为员工登录系统的帐号，保存成功后，暂时不支持修改
					</div>
				</el-form-item>
				<el-form-item label="员工角色 :" label-width="150px" prop="MallDepartmentId">
					<el-select v-model="form.MallDepartmentId" placeholder="请选择" filterable  style="margin-right: 20px;width:300px;">
						<el-option v-for="(item,index) in dataRoles" :key="index" :label="item.Name" :value="item.Id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="归属门店 :" label-width="150px" prop="MallRoleId"  v-if="mallInfo.IsMoreShop&&!IsXiTai">
					<el-select v-model="form.MallRoleId" placeholder="请选择" style="width: 300px;" :disabled='editResign' filterable clearable>
						<!-- <el-option :key="-2" label="总部" :value="0">
						</el-option> -->
						<el-option v-for="(item,index) in doorList" :key="index" :label="item.ShopName" :value="item.Id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企微账号 :" label-width="150px" v-if="iscompletebind">
					<el-input auto-complete="on"  :disabled="editResign||(editDiaTitle=='编辑'&&!caneditlist.includes('QyWeixinUserId'))" placeholder="企业微信成员唯一标识"
						v-model="form.QyWeixinUserId" style="width: 300px;"></el-input>
					<div  style="color:#909399;line-height:20px;font-size:12px;width:300px;overflow-wrap: break-word;">
						前往企业微信后台复制成员帐号，粘贴在此处。保存成功后不支持修改
						<el-link href="https://jusnn6k8al.feishu.cn/docx/doxcnrAsIP8Qx9oDDooSoLqbCTb" target="_blank" :underline="false" type="primary"
							style="font-size:12px">
							操作说明
						</el-link>
					</div>
				</el-form-item>
				<el-form-item label="是否为网页客服 :" label-width="150px" required prop="IsCustomerService" v-if="!IsXiTai" v-show="!editResign">
					<el-radio v-model="form.IsCustomerService" :label="true">是</el-radio>
					<el-radio v-model="form.IsCustomerService" :label="false">否</el-radio>
				</el-form-item>
			</el-form>
			<div style="padding-top: 20px;">
				<el-button @click="cancelSave" style="width: 100px;margin-left: 100px;">关 闭</el-button>
				<el-button type="primary" @click="submitSave" style="width: 100px;margin-left: 30px;" :loading="loading">保 存</el-button>
			</div>
		</el-dialog>

		<!--社群ID -->
		<el-dialog title="社群ID配置" :visible.sync="groupIdVisible" v-if="groupIdVisible" width="700px">
			<el-form :model="groupIdForm" :rules="groupIdFormRules" label-width='100px' ref="groupIdForm">
				<el-form-item label="社群配置ID:" prop="CommunityGroupId" style='margin-bottom:10px;'>
					<el-input auto-complete="on" placeholder="请从企业微信后台复制ID到此处" v-model="groupIdForm.CommunityGroupId" style="width: 500px;"></el-input>
				</el-form-item>
				<el-button type='text' style='margin-left:100px;font-size:12px;' @click='jumpViewTutorial'>点击查看社群ID配置教程>>></el-button>
			</el-form>
			<div style="padding-top:20px;">
				<el-button @click="cancelGroupId" style="width: 100px;margin-left: 200px;">关 闭</el-button>
				<el-button type="primary" @click="submitGroupId" style="width: 100px;margin-left: 30px;" :loading="btnLoading">保 存</el-button>
			</div>
		</el-dialog>

		<el-dialog title="转移失败数据" :visible.sync="errorShow" width="70%">
			<el-table :data="errorList" style="width: 100%">
				<el-table-column prop="EmployeeName" label="客户" width="250px;">
					<template slot-scope="scope">
						<div style="display: flex;flex-direction: row;align-items: center;">
							<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;border:1px solid #ddd;" v-if="scope.row.WxHeadUrl==''"
							 src='https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png' />
							<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;border:1px solid #ddd;" v-else :src='scope.row.WxHeadUrl' />
							<div>
								<div style="margin-left:5px;">{{scope.row.Name?scope.row.Name:scope.row.WxNickname}}</div>
								<div>{{scope.row.Phone}}</div>
							</div>

						</div>
					</template>
				</el-table-column>
				<el-table-column prop="errorMess" label="失败原因">
				</el-table-column>

			</el-table>
		</el-dialog>
		<!-- 选择转移客户弹框 -->
		<el-dialog title="转移客户列表" :visible.sync="fansmemberListShow" width="70%">
			<div class="table-container content">
				<div class="filter-item" style="margin-bottom: 15px;">
					<label class="label">是否已添加企业微信好友: </label>
					<el-select v-model="IsQywxFriend" placeholder="请选择" filterable clearable style="margin-right: 20px;" @change="selectIsQywxFriend">
						<el-option :key="-1" label="全部" :value="null">
						</el-option>
						<el-option v-for="(item,index) in IsQywxFriendlist" :key="index" :label="item.Name" :value="item.Id">
						</el-option>
					</el-select>
				</div>
				<el-table :data="fansDataList" v-loading="fansListLoading" :row-key="getRowKeys" ref="staffSelectFansTable"
				 @selection-change="handleSelectionChange" style="width: 100%">
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column prop="CouponName" label="客户">
						<template slot-scope="scope">
							<div class="dialog-name-content">
								<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;border:1px solid #ddd;" v-if="scope.row.WxHeadUrl==''"
								 src='https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png' />
								<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;" v-else :src='scope.row.WxHeadUrl' />
								<div class="right" style="margin-left:5px;">
									<div class="name">{{scope.row.Name?scope.row.Name:scope.row.WxNickname}}</div>
									<div class="name" v-if='scope.row.Phone'> {{scope.row.Phone}}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="VipRoleName" label="会员级别">
						<template slot-scope="scope">
							<div v-if='scope.row.VipRoleName'>{{scope.row.VipRoleName}}</div>
							<div v-else>--</div>
						</template>
					</el-table-column>
					<el-table-column prop="ShopName" label="归属门店">
						<template slot-scope="scope">
							<div v-if='scope.row.ShopName'>{{scope.row.ShopName}}</div>
							<div v-else>--</div>
						</template>
					</el-table-column>
					<el-table-column prop="IsQywxFriend" label="是否已添加企业微信好友"></el-table-column>
				</el-table>
				<el-checkbox style="margin-top:25px;margin-left:13px;float:left;" :value="checkedAll" @change="selectCurrentAll"></el-checkbox>
				<el-button style="margin-top: 10px;margin-left: 10px;" @click="saveSelectList(0)">转移当前页选中</el-button>
				<el-button style="margin-top: 10px;margin-left: 10px;" @click="saveSelectList(1)">转移全部客户</el-button>
				<el-pagination v-if="fansPage.total" style="margin-top:20px;float:right;" @size-change="handleFansSizeChange"
				 @current-change="handleFansCurrentChange" :current-page="fansPage.current" :page-sizes="[5,10, 15, 20, 25, 30]"
				 :page-size="fansPage.size" layout="total, sizes, prev, pager, next, jumper" :total="fansPage.total">
				</el-pagination>
			</div>
			<div class="dialog-footer">
				<!-- 已选中
				<span style="color:#F56C6C">{{multipleSelection.length}}</span>
				件 -->
				<el-button class="button" @click="saveSelectList(0)" style="width:200px;" type="primary">确定</el-button>
			</div>
		</el-dialog>

		<!-- 转移客户弹框 -->
		<el-dialog title="转移客户" :visible.sync="clientShow" @close="canselEditTransfer" width="600px" :show-close='IshowClose?true:false'
		 :close-on-click-modal='IshowClose?true:false'>
			<div class="direct-recommend-content">
				<div>
					转移客户至：
					<el-input style="width:350px" placeholder="输入员工手机号,点击右侧[查询]" v-model="clientPhone" :disabled="IshowClose?false:true"></el-input>
					<el-button type="primary" @click="handleFilterEm" style="margin-left:20px;" v-if="IshowClose">查询</el-button>
				</div>
				<div style="margin-left:90px;margin-top:10px;color:#E51C23;" v-if="clientMessageShow==1">查询不到相关员工数据，请确认输入手机号无误</div>
				<div style="margin-left:90px;" v-if="clientMessageShow==2">
					<div class="referrer-card">
						<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
							<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
						</div>
						<img :src='clientMessage.WxHeadUrl' v-if="clientMessage.WxHeadUrl" style="width: 50px; height: 50px;border-radius: 100%;" />
						<img v-else src="https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png" alt="" style="width: 50px; height: 50px;border-radius: 100%;">
						<div class="referrer-text">
							<div class="name">{{clientMessage.EmployeeName}}</div>
							<div class="name">{{clientMessage.ShopName}}</div>
						</div>
					</div>
				</div>
			</div>

			<div style="text-align:center;" v-if='operateBtnvisi'>
				<el-button style="margin:30px 30px 0 30px;width:150px;" @click="canselEditTransfer">关闭</el-button>
				<el-button style="width:150px;" type="primary" @click="saveEditTransfer" v-loading='loading'>确认转移</el-button>
			</div>

			<div class="progressBars" style="margin-top: 30px;" v-if="barVisible">
				<el-progress :text-inside="true" :stroke-width="24" :percentage="barPercent" status="success"></el-progress>
			</div>
		</el-dialog>

		<!-- 匿名锁粉弹框 -->
		<el-dialog title="匿名锁粉" :visible.sync="anonymousLockshow" width="800px">
			<label class="label">时间筛选: </label>
			<el-date-picker type="datetime" placeholder="选择日期" v-model="anonymousStarTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
			 format="yyyy-MM-dd HH:mm:ss"></el-date-picker> ~
			<el-date-picker type="datetime" placeholder="选择日期" v-model="anonymousEndTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
			 format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			<el-button type="primary" style="margin:0 0 30px 30px;width:90px;" @click="anonymousFilter">搜索</el-button>

			<el-table :data="anonymousLockData" v-loading="lanonymousLoading" border style="width: 100%">
				<el-table-column prop="EmployeeName" label="匿名用户">
					<template slot-scope="scope">
						<div style="display: flex;flex-direction: row;align-items: center;">
							<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;border:1px solid #ddd;" src='https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png' />
							<span style="margin-left:5px;">{{scope.row.WxAppOpenId}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="锁粉时间"></el-table-column>
			</el-table>

			<div style="text-align: right;">
				<el-pagination v-if="lanonymousTotal" style="margin-top:20px;" @size-change="lanonymousSizeChange" @current-change="lanonymousCurrentChange"
				 :current-page="lanonymousIndex" :page-sizes="[10, 20, 30, 40, 50]" :page-size="lanonymousSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="lanonymousTotal">
				</el-pagination>
			</div>

			<div style="text-align: center;margin-top:30px">
				<el-button type="primary" @click="anonymousLockshow=false">关 闭</el-button>
			</div>

		</el-dialog>

		<!-- 邀请码 -->
		<el-dialog :title="invitInfos.name+ '的邀请码'" :visible.sync="invitVisible" width="500px">
			<div class="invite-dia" >
				<img :src="invitInfos.url">
				<div class="remark">
					客户扫员工邀请码，可进入商城公众号并与员工锁粉
				</div>
			</div>
		</el-dialog>

		<!-- 批量修改角色 -->
		<el-dialog title="批量修改角色" :visible.sync="batchamendshow" v-loading = "batchloading" width="500px" @closed = "batchclose">
			<div style="display:flex;flex-direction:row;margin-left:30px">
				<!-- <div style="font-seze:14px;line-height:37px">员工角色:</div> -->
				<el-form :model="employees" :rules="rules">
					<el-form-item label="员工角色:" prop="employees" style="width:400px">
						<el-select v-model="employees" placeholder="请选择" filterable clearable style="margin-left: 10px;width:300px">
							<el-option v-for="(item,index) in dataRoles" :key="index" :label="item.Name" :value="item.Id">
						</el-option>
					</el-select>
					</el-form-item>
				</el-form>
				
			</div>
			<div style="text-align:center;margin-top:10px">
				<el-button style="width:90px" @click="batchamendshow = false">关闭</el-button>
				<el-button type="primary" style="margin-left:16px" @click="comfigmodification">确认修改</el-button>
			</div>
		</el-dialog>

		<!-- 修改失败数据 -->
		<el-dialog title="修改失败数据" :visible.sync="failuredatashow">
			<div>
				<div style="background:rgb(211,212,214);" class = "failure">
					<div style="width:40%;" class = "failure-title">
						客户
					</div>
					<div style="width:60%;" class = "failure-title">
						失败原因
					</div>
				</div>
				<div style="border:1px solid rgb(211,212,214);border-top:none"  class = "failure" v-for="v in failuredata" :key="v.Id">
					<div style="width:40%;"  class = "failure-title">
						<img style="width:40px;height:40px;border-radius:50%;" :src="v.Result.WxHeadUrl?v.Result.WxHeadUrl:'https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png'" alt="">
						<div style="width:auto;height:100%;margin-left:10px">
							<div style="font-size:14px;line-height:15px;margin-top:10px">{{v.Result.Name}}</div>
							<div style="font-size:14px;line-height:15px">{{v.Result.Phone}}</div>
						</div>
					</div>
					<div style="width:60%;display:table;"  class = "failure-title">
						<div style="height:100%;display:table-cell;vertical-align:middle;">{{v.Result.FailReason}}</div>
					</div>
				</div>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="wxshow" title="关联微信查询结果" center width="420px" class="dialog-body-paddingTop-10" top="35vh">
			<div class="wxclass">
				<div class="rowfont">
					员工{{rowmsg.EmployeeName}}<span v-if="rowmsg.Phone">({{rowmsg.Phone}})</span>，关联的微信客户身份如下：
				</div>
				<img :src="wxmsg.WxHeadUrl||'https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png'" alt="">
				<div class="wxname">{{wxmsg.WxNickName}}</div>
			</div>
			<div>
				<el-button @click="closewxshow" style="margin:0px auto;display:block;margin-top:20px">关闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex'
	import config from '@/config/index'
	import {
		employeelist,
		employeeedit,
		employeeinfo,
		employeedelete,
		employeeroledroplist,
		shopdroplist,
		employeedeparture,
		lockfansmemberlist,
		editlockmallemployeeid,
		anonymousFansList,
		employeeroleidedit,
		employeewxqrcode
	} from '@/api/api'
	import apiList from '@/api/other'
	
	import {
		qyweixinsuiteauthinfo,
		employeeemployeeRelationMemberInfo
	}from "@/api/sv1.0.0"
	import {
		employeeinfobyphone,
		employeedeparturevalitedata
	} from '@/api/wyUsedInterface'
	import buttonPermissions from '@/components/buttonPermissions';

	export default {
		components: {
			buttonPermissions
		},
		name: 'employeeManage',
		data() {
			var checkCommunityGroupId = (rule, value, callback) => {
				if (/[\u4E00-\u9FA5]/g.test(value)) {
					return callback(new Error('请输入正确的配置ID'));
				} else {
					return callback()
				}
			};
			var checkPhone = (rule, value, callback) => { 
				if(!value){
					return callback(new Error('请输入员工手机号'));
				}else if (value.length!=11 && !this.editResign){
					return callback(new Error('请输入正确的手机号'));
				}else {
					return callback()
				}
			};
			return {
				img:'',
				checked:false,//当前页面全选
				checkoption:[],//选中的选项
				rules:{
					employees:[{ required: true, message: '请选择员工角色', trigger: 'blur' },]
				},

				batchclickshow:false,//批量修改按钮
				
				batchamendshow:false,//批量修改数据
				batchloading:false,
				employees:null,

				failuredatashow:false,//修改失败数据
				failuredata:[],

				IsQywxFriend:null,
				IsQywxFriendlist:[{Name:'是',Id:1},{Name:'否',Id:0}],
				errorList:[],
				errorShow: false,
				membersId: [],
				CurrentIndex: 0,
				barPercent: 0,
				operateBtnvisi: false,
				barVisible: false,
				IshowClose: true,
				alltransferlist: [],
				goUrls: config.GO_URL,
				anonymousEmployeeId: '',
				lanonymousTotal: 0,
				lanonymousSize: 10,
				lanonymousIndex: 1,
				anonymousLockData: [],
				lanonymousLoading: false,
				anonymousStarTime: '',
				anonymousEndTime: '',
				anonymousLockshow: false,
				editDiaTitle: '添加',
				editLoading: false,
				imgUrl: config.IMG_BASE,
				EmployeeState: 1,
				EmployeeStateList: [{
					Id: 1,
					Name: '在职'
				}, {
					Id: 2,
					Name: '待分配'
				}, {
					Id: 0,
					Name: '离职'
				}],

				IsCustomerService: null,
				CustomerServiceList: [{
					Id: 'true',
					Name: '客服'
				}, {
					Id: 'false',
					Name: '其他'
				}],
				loading: false,
				searchKey: null,
				rewriteBox: false,
				FormDate: {
					passWorld: '',
					repassWorld: ''
				},
				tableData: [],
				pageIndex: 1,
				pageSize: 20,
				total: 0,
				FormDateIds: null,

				dialogFormVisible: false,
				form: {
					EmployeeState:0,
					EmployeeName: '',
					Phone: '',
					MallDepartmentId: null,
					MallRoleId: null,
					IsCustomerService: false,
					QyWeixinUserId:''
				},
				editResign: false, //编辑离职员工
				changephone:false,
				formRules: {
					EmployeeName: [{
						required: true,
						message: '请输入员工姓名',
						trigger: 'blur'
					}],
					Phone: [{
							required: true,
							trigger: 'blur' ,
							validator: checkPhone,
						},
					],
					MallDepartmentId: [{
						required: true,
						message: '请选择员工角色',
						trigger: 'change'
					}],
					MallRoleId: [{
						required: true,
						message: '请选择归属门店',
						trigger: 'change'
					}],
				},

				departments: [],
				doorList: [],
				roles: [],
				dataRoles: [],
				dataRolesType: null,
				doorListType: null,
				fansDataList: [],
				fansmemberListShow: false,
				fansListLoading: false,
				fansPage: {
					current: 1,
					total: 0,
					size: 5
				},
				clientShow: false,
				clientPhone: '',
				clientMessage: {},
				clientMessageShow: 0,
				clientChecked: false,
				clientCheckAll: false,
				multipleSelection: [],
				valitedata: {},
				oldPhone: '',
				groupIdVisible: false,
				btnLoading: false,
				groupIdForm: {
					id: '',
					CommunityGroupId: ''
				},
				groupIdFormRules: {
					CommunityGroupId: [{
						validator: checkCommunityGroupId
					}],
				},
				IsassociateConfig: false,

				invitInfos: {
					name: '',
					url: '',
					id: '',
				},
				invitVisible: false,
				iscompletebind:false,
				caneditlist:[],
				caneditphone:false,

				wxshow:false,
				rowmsg:{},
				wxmsg:{},
			}
		},
		created() {
			// console.log(this.EmployeeState)
		},
		mounted() {
			this.routerList = []
			this.permission_routers.map(record => {
				if (record.meta && record.meta.title) {
					this.routerList.push(record)
				}
			})
			let associtelist = []
			this.routerList.map(item => {
				if (item.path == '/market') {
					associtelist.push(item)
				}
			})
			associtelist[0].children.map(item => {
				if (item.children && item.children.length && item.path == '/market/association') {

					item.children.some(items => {

						if (items.path == '/market/association/associateConfig') {

							this.IsassociateConfig = true
						}
					})
				}
			})

		},
		beforeMount() {
			// console.log(this.mallInfo,'111')
			// console.log(JSON.parse(this.$route.query.message))
			// console.log(this.$route.query.message)
			this.getRoleList();
			if(this.$route.query.message){
				this.dataRolesType = JSON.parse(this.$route.query.message).Id
			}
			this.getShopList();
			this.getList();
			this.getiscompletebind()
			
		},
		computed: {
			...mapGetters([
				'permission_routers',
				"mallInfo",
				'IsXiTai'
			]),
			checkedAll() {
				var val = this.multipleSelection;
				let ableSelectLength = 0 //获取当前页可选的全部页数
				var remarkLen = 0;
				var ids = val.map(item => {
					return item.Id
				})
				if (this.fansDataList.length) {
					this.fansDataList.forEach(item => {
						if (ids.indexOf(item.Id) > -1 || item.Remark) {
							ableSelectLength++;
						}
						if (item.Remark) {
							remarkLen++;
						}
					});
					return (ableSelectLength > 0 && this.fansDataList.length == ableSelectLength && remarkLen != ableSelectLength) ?
						true : false;
				} else {
					return false
				}

			}
		},
		methods: {
			closewxshow(){
				this.wxshow = false
				this.rowmsg = {}
				this.wxmsg = {}
			},
			async searchwx(e){
				try{
					this.loading = true
					let row = await e
					this.rowmsg = row
					let res = await employeeemployeeRelationMemberInfo({
						EmployeeId:row.Id
					})
					if(res.IsSuccess){
						this.wxmsg = res.Result
						this.wxshow = true
					}
				}finally{
					this.loading = false
				}
			},
			async getiscompletebind(){
				try{
					let res = await qyweixinsuiteauthinfo()
					this.iscompletebind = res.Result.ConfigStep == 10
				}finally{
					//
				}
				
			},
			batchclose(){
				// console.log(1)
				this.employees = null
			},
			//当前页全选
			tabCheckedChange() {
				this.$refs['tableemployees'].toggleAllSelection();
			},
			//是否全选  勾选表格选项触发
			SelectionChange(val){
				this.checkoption = val
				// console.log(val.length)
				if(this.tableData.length == val.length){
					this.checked = true
				}else{
					this.checked = false
				}
			},
			//批量修改
			batchamend(){
				// console.log(this.checkoption.length)
				if(this.checkoption.length<=0){
					this.$message.error('请先选择员工')
				}else{
					this.batchamendshow = true
				}
			},
			//确认修改
			async comfigmodification(){
				if(!this.employees){
					this.$message.error('请选择员工角色')
				}else{
					this.amendrole()
				}
			},
			//循环修改
			async amendrole(){
				
					this.batchloading = true

					let arr = []
					for(let v of this.checkoption){
						let data = {
							EmployeeRoleId:this.employees,
							EmployeeId :v.Id
						}
						let result = await employeeroleidedit(data)
						arr.push(result)
					}
					// console.log(arr)
					this.failuredata = arr.filter((v)=>{
						return v.Result
					})
					// console.log(this.failuredata)
					if(this.failuredata.length > 0){
						this.failuredatashow = true
						this.batchamendshow = false
					}else{
						this.$message.success('修改成功')
						this.batchamendshow = false
						this.getList()
					}
					this.batchloading = false
			},


			selectIsQywxFriend(){
				this.getFansList(this.EmployeeId)
			},
			// 匿名粉丝
			anonymousLock(record) {
				this.anonymousEmployeeId = record
				this.anonymousData()
				this.anonymousLockshow = true
			},
			// 匿名粉丝搜索
			anonymousFilter() {
				this.lanonymousIndex = 1;
				this.anonymousData()
			},
			// 匿名粉丝列表
			async anonymousData() {
				try {
					this.lanonymousLoading = true
					let data = {
						EmployeeId: this.anonymousEmployeeId,
						StartTime: this.anonymousStarTime,
						EndTime: this.anonymousEndTime,
						Skip: (this.lanonymousIndex - 1) * this.lanonymousSize,
						Take: this.lanonymousSize,
					}
					let result = await anonymousFansList(data)
					if (result.IsSuccess) {
						this.anonymousLockData = result.Result.Results;
						this.lanonymousTotal = result.Result.Total;
					}

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.lanonymousLoading = false
				}

			},
			// 切换显示条数
			lanonymousSizeChange(val) {
				this.lanonymousSize = val;
				this.anonymousData();
			},
			// 翻页
			lanonymousCurrentChange(val) {
				this.lanonymousIndex = val;
				this.anonymousData();
			},
			async getList() {

				this.loading = true;
				this.batchclickshow = this.EmployeeState == 1?true:false
				const res = await employeelist({
					KeyWords: this.searchKey,
					EmployeeRoleId: this.dataRolesType,
					ShopId: this.doorListType,
					EmployeeState: this.EmployeeState,
					Skip: (this.pageIndex - 1) * this.pageSize,
					Take: this.pageSize,
					IsCustomerService: this.IsCustomerService
				})

				this.loading = false;

				if (res.IsSuccess) {
					this.tableData = res.Result.Results;
					this.total = res.Result.Total;
				}
			},
			async getRoleList() {
				const res = await employeeroledroplist();
				if (res.IsSuccess) {
					this.dataRoles = res.Result;
				}
				
				// console.log(this.dataRoles)
			},
			async getShopList() {
				const res = await shopdroplist({
					IsOpen: true
				});
				if (res.IsSuccess) {
					this.doorList = res.Result;
					// console.log(this.doorList,'门店名称')
				}
			},
			//跳转查询客户锁粉
			jumpToLockCustomer(id) {
				this.$router.push({
					path: '/Customer/CustomerSearch',
					query: {
						Id: id
					}
				})
			},
			// 筛选
			handleFilter() {
				this.pageIndex = 1;
				this.getList();
			},

			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.pageIndex = val;
				this.getList();
			},
			// 部门的选择
			departMents() {

			},
			// 添加员工
			AddEmployee() {
				this.editDiaTitle = '添加'
				this.form = {
					Id: 0,
					EmployeeName: '',
					Phone: '',
					MallDepartmentId: '',
					MallRoleId: '',
					IsCustomerService: false,
					QyWeixinUserId:''
				}
				this.dialogFormVisible = true;
				this.editResign = false
				this.changephone = false
				this.caneditphone = false
				this.caneditlist = []
			},
			// 编辑员工信息
			handleEdit(index, row) {
				this.form = {
					Id: 0,
					EmployeeName: '',
					Phone: '',
					MallDepartmentId: '',
					MallRoleId: '',
					IsCustomerService: false,
					QyWeixinUserId:''
				}
				this.editDiaTitle = '编辑'
				this.getEmployeeInfo(row);
				this.dialogFormVisible = true;
				this.editResign = row.EmployeeState == 0
				this.changephone = row.EmployeeState == 1//在职不可编辑手机号
			},
			async getEmployeeInfo(record) {
				try{
					this.editLoading = true
					this.caneditphone = false
					this.caneditlist = []	
					const res = await employeeinfo({
						Id: record.Id
					})
					this.form = {
						EmployeeName: res.Result.EmployeeName,
						Phone: res.Result.Phone,
						MallDepartmentId: (!res.Result.MallEmployeeRoleId) ? null : res.Result.MallEmployeeRoleId,
						MallRoleId: res.Result.ShopId,
						Id: res.Result.Id,
						IsCustomerService: res.Result.IsCustomerService,
						QyWeixinUserId:res.Result.QyWeixinUserId,
					}
					for(let key in this.form){
						if(!this.form[key]){
							this.caneditlist.push(key)
						}
					}
					if(this.form.Phone){
						this.caneditphone = true
					}
					// console.log(this.form)
					// console.log(this.caneditlist.includes('QyWeixinUserId'))
				} catch(e) {
					//
				}finally {
					this.editLoading = false
				}

			},
			async handleInviteCode(val) {
				this.invitInfos.url = ''
				this.invitInfos.name = val.EmployeeName
				try{
					const res = await employeewxqrcode({
						Id: val.Id
					})
					this.invitInfos.url = res.Result.WxQrCode
				} catch(e) {

				}finally {
					this.invitVisible= true
				}
			},
			// 删除
			async handleLeave(index, row) {
				// console.log(row)
				try {
					let res = await employeedeparturevalitedata({
						Id: row.Id
					});
					this.valitedata = res.Result
				} catch (e) {

				}
				let text = ''
				if(this.IsXiTai){
					text = `<div style='width:100%;'>
					是否确认离职员工?
				</div>`
				}else{
					text = `<div style='width:100%;'>
					<div> ${row.EmployeeName}（${row.Phone}）账户上目前还存在：</div>
					<div>（1）待结算提成 ${this.valitedata.UnfinishBalance }元</div>
					<div>（2）提现中提成 ${this.valitedata.DrawingBalance }元</div>
					<div>（3）剩余可提现提成 ${this.valitedata.WaitDrawBalance }元</div>
					<div>若直接离职，提现中提成可正常审核，待结算提成和剩余可提现将不再结算。是否确认继续办理离职？</div>
				</div>`
				}
				this.$alert(text,
					'提示', {
						dangerouslyUseHTMLString: true,
						customClass: 'valitedata-dialog-alert',
						showCancelButton: true,
						confirmButtonText: '确认离职',
						cancelButtonText: '关闭',
					}).then(async () => {
					let result = await employeedeparture({
						Id: row.Id
					});
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功！'
						});

					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: result.Message
						});
					}
				}).catch(() => {
					// this.$message({
					// 	showClose: true,
					// 	type: 'info',
					// 	message: '取消离职操作'
					// });
				}).finally(() => {
					this.handleFilter()
				})
			},

			// 保存员工信息
			submitSave() {
				if(this.editResign){
					this.saveResign()
				}else{
					this.saveOnJob()
				}
			},
			async saveResign(){
				this.$refs['form'].validate( async (valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true,
						});
						try {
							let result = await employeeroleidedit({
								EmployeeRoleId: this.form.MallDepartmentId,
								EmployeeId: this.form.Id
							});
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '编辑成功！'
								});
								this.dialogFormVisible = false;
								this.$refs['form'].resetFields();
							}
						} catch (err) {
							console.log(err);
						} finally {
							loading.close();
							this.handleFilter()
						}
					}
				})
			},
			saveOnJob(){
				this.$refs['form'].validate( async (valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true,
						});
						try {
							this.loading = true
							let data = {
								EmployeeName: this.form.EmployeeName,
								MallEmployeeRoleId: this.form.MallDepartmentId,
								ShopId: this.IsXiTai?0:this.form.MallRoleId,
								Phone: this.form.Phone,
								IsCustomerService: this.form.IsCustomerService,
								Id: this.form.Id,
								QyWeixinUserId:this.form.QyWeixinUserId,
							}
							let result = await employeeedit({
								EmployeeInfo: data
							});
							if (result.IsSuccess) {
								if (this.editDiaTitle == '添加') {
									this.$message({
										showClose: true,
										type: 'success',
										message: '添加成功！'
									});
								} else {
									this.$message({
										showClose: true,
										type: 'success',
										message: '保存成功！'
									});
								}
								this.dialogFormVisible = false;
								this.$refs['form'].resetFields();
							} else {
								this.$message({
									showClose: true,
									type: 'info',
									message: result.Message
								});
							}
						} catch (err) {
							console.log(err);
						} finally {
							this.loading = false
							this.searchKey = ''
							this.handleFilter()
							loading.close();
						}

					} else {
						return false;
					}
				});
			},
			handleMigrate(record) {
				this.EmployeeId = record.Id
				//获取全部的转移客户列表
				this.getAlltransferlist(this.EmployeeId)
				if (record.EmployeeStateValue == '在职' || record.EmployeeStateValue == '待分配') {
					this.$confirm('员工未离职，转移客户仅修改商城内锁粉关系，不会修改企业微信好友关系，请确认是否继续转移', '提示', {
						confirmButtonText: '继续转移',
						cancelButtonText: '关闭',
						type: 'warning'
					}).then(() => {
						this.clientMessageShow = 0
						this.clientCheckAll = false,
						this.clientMessage = {}
						this.multipleSelection = []
						this.clientPhone = ''
						this.$nextTick(() => {
							this.$refs.staffSelectFansTable.clearSelection();
						})
						this.fansmemberListShow = true
						this.getFansList(this.EmployeeId)

					}).catch(() => {
						this.$message({
							showClose: true,
							type: 'info',
							message: '已取消操作'
						});
					}).finally(() => {})
				} else {
					//初始化
					this.clientMessageShow = 0
					this.clientCheckAll = false,
						this.clientMessage = {}
					this.multipleSelection = []
					this.clientPhone = ''
					this.$nextTick(() => {
						this.$refs.staffSelectFansTable.clearSelection();
					})
					this.fansmemberListShow = true
					this.getFansList(this.EmployeeId)
				}

			},
			async getAlltransferlist() {
				let data = {
					EmployeeId: this.EmployeeId,
					IsQywxFriend:this.IsQywxFriend,
					Skip: 0,
					Take: 1000000,
				}
				let result = await lockfansmemberlist(data)
				this.alltransferlist = result.Result.Results
			},
			async getFansList() {
				let data = {
					EmployeeId: this.EmployeeId,
					IsQywxFriend:this.IsQywxFriend,
					Skip: (this.fansPage.current - 1) * this.fansPage.size,
					Take: this.fansPage.size,
				}
				let result = await lockfansmemberlist(data)
				this.fansDataList = result.Result.Results
				this.fansPage.total = result.Result.Total
			},
			cancelSave() {
				this.dialogFormVisible = false;
				this.$refs['form'].resetFields();
			},
			saveSelectList(val) {
				//转移全部的客户
				if (val) {
					this.IshowClose = true
					this.barPercent = 0
					this.operateBtnvisi = true
					this.barVisible = false
					this.clientShow = true
					this.oldPhone = this.clientPhone
					this.fansmemberListShow = false
					this.multipleSelection = this.alltransferlist
				} else {
					if (!this.multipleSelection.length) {
						this.$message({
							showClose: true,
							message: '请选择客户',
							type: 'info'
						})
						return
					} else {
						this.barPercent = 0
						this.operateBtnvisi = true
						this.barVisible = false
						this.clientShow = true
						this.oldPhone = this.clientPhone
						this.fansmemberListShow = false
						this.IshowClose = true
					}
				}

			},

			async handleGroupId(val) {
				try {
					const res = await employeeinfo({
						Id: val.Id
					})
					if (res.IsSuccess) {
						this.groupIdForm.CommunityGroupId = res.Result.CommunityGroupId
						this.groupIdForm.Id = val.Id
					}
				} catch (e) {

				} finally {
					this.groupIdVisible = true
				}
			},
			cancelGroupId() {
				this.groupIdVisible = false
			},
			jumpViewTutorial() {
				if (this.IsassociateConfig) {
					let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
					let url = this.goUrls + headsUrls + '/market/association/associateConfig'
					window.open(url);
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: '暂无页面权限！'
					});
				}
			},
			async submitGroupId() {
				try {
					this.btnLoading = true
					let data = {
						Id: this.groupIdForm.Id,
						CommunityGroupId: this.groupIdForm.CommunityGroupId
					}
					let result = await apiList.setEmployeeCommunityGroupId(
						data
					);
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '保存成功！'
						});
					}
				} catch (err) {
					console.log(err);
				} finally {
					this.btnLoading = false
					this.groupIdVisible = false
				}
			},
			handleFansSizeChange(val) {
				this.fansPage.size = val
				this.getFansList()
			},
			handleFansCurrentChange(val) {
				this.fansPage.current = val
				this.getFansList()
			},
			getRowKeys(row) {
				return row.Id;
			},
			//外部全选当前页
			selectCurrentAll(e) {
				this.$refs['staffSelectFansTable'].toggleAllSelection()
			},
			//当前页全选
			handleSelectionChange(val) {
				this.multipleSelection = val;

			},
			async handleFilterEm() {
				this.clientMessageShow = 0
				this.oldPhone = this.clientPhone
				try {
					let result = await employeeinfobyphone({
						Phone: this.clientPhone
					})
					if (result.Result.Id == 0) {
						this.clientMessageShow = 1
					} else {
						this.clientMessage = result.Result
						this.clientMessageShow = 2
					}
				} catch (e) {

				} finally {

				}
			},
			canselEditTransfer() {
				this.clientShow = false
			},
			saveEditTransfer() {
				if (this.oldPhone != this.clientPhone) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请点击[查询]确认员工信息'
					});
					return false
				}
				if (this.clientMessageShow == 1) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入正确的员工信息'
					});
					return false
				}
				if (!this.clientPhone) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入员工手机号'
					});
					return false
				}
				this.loading = true
				this.membersId = []
				this.membersId = this.multipleSelection.map(t => {
					return t.Id
				})
				
				this.operateBtnvisi = false;
				this.IshowClose = false
				this.barVisible = true
				this.CurrentIndex = 1

				this.errorList = []
				this.getmemberResults()

			},
			async getmemberResults() {
				try {
					if (this.CurrentIndex > this.membersId.length) {
						return;
					}
					let data = {
						IsAll: false,
						MemberIds: [this.membersId[this.CurrentIndex - 1]],
						EmployeeId: this.EmployeeId,
						NewEmployeeId: this.clientMessage.Id,
					}
					let result = await editlockmallemployeeid(data)
				} catch (err) {
					let item = this.multipleSelection.find(item => {
						return item.Id == this.membersId[this.CurrentIndex - 1]
					})
					if (item) {
						item.errorMess = err
						this.errorList.push(item)
					}
					this.loading = false
				} finally {
					this.loading = false
					let step = parseInt(100 / this.membersId.length)
					if (this.CurrentIndex == this.membersId.length) {
						this.barPercent = 100
						if (this.errorList.length) {
							setTimeout(() => {
								this.errorShow = true
								this.clientShow = false
								this.IshowClose = true
							}, 1000)
							
						} else {
							setTimeout(() => {
								this.IshowClose = true
								this.clientShow = false
								this.$message({
									showClose: true,
									type: 'success',
									message: '转移成功'
								})
								this.getList()
							}, 1000)
						}

						this.loading = false
					} else {
						this.barPercent = step * this.CurrentIndex
						this.CurrentIndex++;
						this.getmemberResults();
						this.loading = false
					}
				}
			},
		},
		destroyed() {}
	}
</script>
<style lang="less" scoped>
.wxclass{
	display: flex;
	flex-direction: column;
	align-items: center;
	.rowfont{
		font-size: 14px;
		color: #999999;
		line-height: 1.5;
	}
	img{
		width: 60px;
		height: 60px;
		border-radius: 50%;
		margin: 40px 0px 10px 0px;
	}
	.wxname{
		font-size: 14px;
		color: #606266;
		line-height: 1.5;
	}
}
	.yellowbox{
		background: #FDF6EC;
		border-radius: 2px 2px 2px 2px;
		padding:15px;
		margin:0px 10px 20px 10px;
		font-size: 14px;
		color: #E6A23C;
		line-height: 1.5;
	}
	.dialog-name-content {
		display: flex;
		flex-direction: row;

		.name {
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}
	}

	.dialog-footer {
		margin-top: 20px;
		text-align: center;
	}

	.referrer-card {
		img {
			width: 50px;
			height: 50px;
			border-radius: 100%;
			object-fit: contain;
			margin-right: 10px;
		}

		width: 260px;
		height: 60px;
		border: 1px solid #67C23A;
		margin-top: 10px;
		border-radius: 1px;
		position: relative;
		padding: 5px;
		display: flex;
		flex-direction: row;
		line-height: 20px;
		align-items: center;

		.check {
			position: absolute;
			right: -1px;
			top: -4px;
		}

		::v-deep .el-checkbox {
			width: 14px;
			height: 14px;
		}

		::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
		.el-checkbox__input.is-indeterminate .el-checkbox__inner {
			background-color: #67C23A;
			border-color: #67C23A;
		}

		::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
			border-color: #67C23A;
		}

		::v-deep .el-checkbox__inner:hover {
			border-color: #67C23A;
		}

		.referrer-text {
			display: inline-block;
			width: 200px;
			overflow: hidden;
		}

		.name {
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

		}
	}
	.invite-dia {
		text-align: center;
		img{
			width: 430px;
			height: 430px;
		}
		.remark {
			color: #909399;
			font-size: 12px;
			text-align: center;	
			margin-top:10px
		}
	}
	.failure{
		width:100%;height:auto;display:flex;flex-direction:row;padding:10px 5px
	}
	.failure-title{
		font-size:14px;color:black;padding-left:15px;display:flex;flex-direction:row;line-height:50px
		}
</style>
<style>
	.valitedata-dialog-alert {
		width: 550px !important;
	}
	.dialogpad .el-dialog__body{
		padding: 20px 20px 20px 20px ;
	}
</style>
